<template>
    <div>
         <con-tab @TwoClick="TwoClick"></con-tab>
        <!--智产云-->
        <zhichanyun-form ></zhichanyun-form>
    </div>
</template>

<script>
    import ConTab from "../../components/common/ConTab";
    import zhichanyunForm from "./base/zhichanyunForm";
     export default {
        name: "Kefuyun",
        data(){
            return {
                
            }
        },
        created(){
            
        },
        methods:{
             TwoClick(item){
                //console.log(item);
                this.ConList = item
            },
        },
        components:{
            ConTab,
            zhichanyunForm
        }
    }
</script>

<style scoped>

</style>
