<template>
  <!-- 标题 -->
  <div class="banxin">
  <div class="kf_con">
      <div class=" xinxi"  v-for="(item ,index) in aalist" :key="index">
      	<img src="../../../assets/img/images/lia_03.png" v-if="index ===0" class="xinpic" alt="">
      	<img src="../../../assets/img/images/lib_03.png" v-if="index ===1" class="xinpic" alt="">
      	<img src="../../../assets/img/images/lic_03.png" v-if="index ===2" class="xinpic" alt="">
      	<img src="../../../assets/img/images/lid_03.png" v-if="index ===3" class="xinpic" alt="">
      	
		<router-link :to="{ path: 'Patent',  query: { i: index } }">
		           <div class="xinyou">
		           	<p class="bule">
		           		<number-roller :num="item.num"></number-roller>
		           	</p>
		           	<p>{{ item.name }}</p>
		           </div>
		          </router-link>
      </div>
    </div>
  </div>
</template>
 
<script>
import { Data } from "../../../common/js/api";
export default {
  components: {
    NumberRoller: () => import("@/common/number-roller.vue"),
  },
  data() {
    return {
	  aalist:[
		  
	  ],
    };
  },
  created() {
    this.getNums();
  },
  methods: {
    // 数据
        getNums() {
          Data()
            .then((res) => {
			  var number = res.data[1].nums.map(item=>{
			  			  item.num*=1
			  			  return item
			  })
			  this.aalist = number
            })
            .catch(() => {});
        },
  },
};
</script>

<style lang="less" scoped>
.banxin{
	width: 1400px;
}
/* 标题 */
.kf_con {
  height: 190px;
  box-sizing: border-box;
  margin-top: -65px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.xinxi{
	height: 140px;
	width: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-right: 1px solid #e5e5e5;
}
.xinxi:last-child{
	border: none,
}
.xinpic{
	width: 62px;
	height: 59px;
	transition: all 0.5s;
}
.xinxi:hover{
	.xinpic{
		transform: scale(1.2);
	}
}
.xinyou{
	padding-left: 20px;
}
.kf_con .col {
  position: relative;
}
.kf_con p {
  font-size: 18px;
  text-align: left;
  font-family: SourceHanSansSC-regular;
  color: #333333;
  text-align: left;
  padding-left: 5px;
}

.kf_con .bule {
  font-size: 42px;
  font-style: italic;
  color: #ff4200;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}
@media screen and (max-width: 1200px) {
	.kf_con{
		margin-top: 0;
	}
	.banxin{
		width: 100% !important;
	}
	.kf_con p{
		font-size: 1.3rem;
	}
}
/*  */
</style>