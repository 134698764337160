<template>
	<div>
		<kefuright></kefuright>
		<home-swiper> </home-swiper>
		<div class="jisubox">
			<div class="banxin">
				<Con></Con>
				<div class="chanbg animate__animated animate__fadeInUp"  >
					<div class="channei">
						<div class="chantop">
							知识产权
						</div>
						<div class="chantxt twolines">
							用于展示企业、机构、科研院所、创业团队或创新个人发布技术转让或出售信息，包括专利或非专利技术商标、软件著作权等知识产权或技术。需求方科在线购买使用。
						</div>
						<div class="chanjin" @click="zl">
							进入知识产权发布入口
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 知识产权-专利展示 -->
		<div class="quanbg">
			<div class="pt60" id="tab2">
				<div class="gongtop">
					<img src="../../../assets/img/images/tbl_08.png" class="zhengpic" alt="" />
					<div class="zhengbai" >知识产权-专利展示</div>
					<img src="../../../assets/img/images/tbr_08.png" class="zhengpic" alt="" />
				</div>
				<div class="peotitle">
					提供专利转让发布、专利技术展示、专利技术转让，专注专利项目转化、企业专利技术创新需求。
				</div>
			</div>
		</div>
		
		<div class="jingbox">
			<div class="banxin">
				<div class="jingbai">
					<div class="peoul">
						<div class="peoli" v-for="(item , index) in WisdomLista" :key="index" @click="xq(item.id)">
							<div class="peotitle oneline" >
								{{item.name}}
							</div>
							<div class="fuul">
								<div class="fuli">
									{{item.apply_class == 1 ? "个人" : "企业"}}
								</div>
								<div class="fuli">
									{{item.notary_number}}
								</div>
								<div class="fuli">
									{{item.registration_date |  formatDate}}
								</div>
							</div>
							<div class="kfbox">
								<div class="kfleft " @click="zx">
									<img src="../../../assets/img/images/kefu_06.png" class="kfpic" alt="" />
									<div class="kfltxt">
										咨询客服
									</div>
								</div>
								<div class="flmian">面议</div>
							</div>
						</div>
					</div>
					<div class="zhuce" @click="need(0)">进入专题页面</div>
				</div>
			</div>
		</div>
		<!-- 知识产权-软著展示 -->
		<div class="ruanbox">
			<div class="banxin">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">知识产权-软著展示</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="rubox">
					<div class="ruleft" @click="need(1)">
						<div class="rutitle">
							软著展示
						</div>
						<div class="rutxt">
							陈列技术成果相关软著，转让使用许可权。
						</div>
						<div class="rukbox" @click="need(1)">
							进入专题页面
						</div>
					</div>
					<div class="ruyou">
						<div class="peoli" v-for="(item , index) in WisdomListb" :key="index" @click="xq(item.id)">
							<div class="peotitle oneline">
								{{item.name}}
							</div>
							<div class="fuul">
								<div class="fuli">
									{{item.apply_class == 1 ? "个人" : "企业"}}
								</div>
								<div class="fuli">
									{{item.notary_number}}
								</div>
								<div class="fuli">
									{{item.registration_date | formatDate}}
								</div>
							</div>
							<div class="kfbox">
								<div class="kfleft " @click="zx">
									<img src="../../../assets/img/images/kefu_06.png" class="kfpic" alt="" />
									<div class="kfltxt">
										咨询客服
									</div>
								</div>
								<div class="flmian">面议</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 知识产权-商标展示 -->
		<div class="dingbg">
			<div class="pt60">
				<div class="gongtop">
					<img src="../../../assets/img/images/tbl_08.png" class="zhengpic" alt="" />
					<div class="zhengbai">知识产权-商标展示</div>
					<img src="../../../assets/img/images/tbr_08.png" class="zhengpic" alt="" />
				</div>
				<div class="peotitle">
					陈列商标栏目，实现在线上咨询，购买商标。
				</div>
			</div>
		</div>
		<div class="jingbox">
			<div class="banxin">
				<div class="jingbai">
					<div class="peoul">
						<div class="peoli" v-for="(item , index) in WisdomListc" :key="index" @click="xq(item.id)" >
							<div class="peotitle oneline">
								{{item.name}}
							</div>
							<div class="fuul">
								<div class="fuli">
									{{item.apply_class == 1 ? "个人" : "企业"}}
								</div>
								<div class="fuli">
									{{item.notary_number}}
								</div>
								<div class="fuli">
									{{item.registration_date | formatDate}}
								</div>
							</div>
							<div class="kfbox">
								<div class="kfleft " @click="zx">
									<img src="../../../assets/img/images/kefu_06.png" class="kfpic" alt="" />
									<div class="kfltxt">
										咨询客服
									</div>
								</div>
								<div class="flmian">面议</div>
							</div>
						</div>
					</div>
					<div class="zhuce" @click="need(2)">进入专题页面</div>
				</div>
			</div>
		</div>
		
		<!-- 知识产权-其他展示 -->
		<div class="ruanbox">
			<div class="banxin">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">知识产权-其他展示</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="rubox">
					<div class="ruleft ruact" @click="need(3)">
						<div class="rutitle">
							其他展示
						</div>
						<div class="rutxt">
							陈列其他栏目，实现在线上咨询。
						</div>
						<div class="rukbox">
							进入专题页面
						</div>
					</div>
					<div class="ruyou">
						<div class="peoli" v-for="(item , index) in WisdomListd" :key="index" @click="xq(item.id)">
							<div class="peotitle oneline">
								{{item.name}}
							</div>
							<div class="fuul">
								<div class="fuli">
									{{item.apply_class == 1 ? "个人" : "企业"}}
								</div>
								<div class="fuli">
									{{item.notary_number}}
								</div>
								<div class="fuli">
									{{item.registration_date | formatDate}}
								</div>
							</div>
							<div class="kfbox">
								<div class="kfleft " @click="zx">
									<img src="../../../assets/img/images/kefu_06.png" class="kfpic" alt="" />
									<div class="kfltxt">
										咨询客服
									</div>
								</div>
								<div class="flmian">面议</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 技术展示 -->
		<div class="jishubg" id="tab1">
			<div class="pt60">
				<div class="gongtop">
					<img src="../../../assets/img/images/tbl_08.png" class="zhengpic" alt="" />
					<div class="zhengbai">技术展示</div>
					<img src="../../../assets/img/images/tbr_08.png" class="zhengpic" alt="" />
				</div>
				<div class="peotitle">
					提供各行业科研成果，满足各项技术成果转化。
				</div>
			</div>
		</div>
		<div class="jingbox">
			<div class="banxin">
				<div class="jingbai">
					<div class="peoul">
						<div class="peoli" v-for="(item , index) in WisdomListe" :key="index"  @click="cxq(item.id)">
							<div class="peotitle oneline">
								{{item.name}}
							</div>
							<div class="fuul">
								<div class="fuli" v-if="item.projecty">
									成果阶段：{{item.projecty }}
								</div>
								<div class="fuli" v-else>
									成果阶段：无
								</div>
								<div class="fuli" v-if="item.territory.name">
									成果领域：{{item.territory.name }}
								</div>
								<div class="fuli" v-else>
									成果阶段：无
								</div>
							</div>
							<div class="kfbox">
								<div class="kfleft " @click="zx">
									<img src="../../../assets/img/images/kefu_06.png" class="kfpic" alt="" />
									<div class="kfltxt">
										咨询客服
									</div>
								</div>
								<div class="flmian">面议</div>
								
							</div>
						</div>
					</div>
					<div class="zhuce" @click="goTomember">进入专题页面</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import kefuright from '../../../components/common/kefu';
	import $ from "jquery";
	import HomeSwiper from "../../../components/common/Swiper";
	import FromList from "../../../common/input";
	import Con from "./kcon";
	import {
		Wisdom,
		achievementsLists,
		Data
	} from "../../../common/js/api";
	import {
		zhi
	} from "../../common/js/api";
	import {
		formatDate
	} from "../../../common/js/times"; //时间

	export default {
		name: "zhichanyunForm",
		components: {
			FromList,
			HomeSwiper,
			Con,
			kefuright,
			NumberRoller: () => import("@/common/number-roller.vue"),
		},
		filters: {
			formatDate(time) {
				time = time * 1000;
				let date = new Date(time);
				return formatDate(date, 'yyyy-MM-dd');
			}
		},
		data() {
			return {
				WisdomLista:[],
				WisdomListb:[],
				WisdomListc:[],
				WisdomListd:[],
				WisdomListe:[]
			};
		},
		created() {
			this.getList()
			this.getWisdom();
			this.getNums();
		},
		methods: {
			 //智产云  主页 内容
			    getWisdom() {
			      Wisdom()
			        .then((res) => {
						var itemss = res.data.one.slice(0, 8);
			          this.WisdomLista = Array.from(itemss).reverse()
			          this.WisdomListb = res.data.two.slice(0, 6);
			          this.WisdomListc = res.data.there.slice(0, 8);
			          this.WisdomListd = res.data.four.slice(0, 6);
			        })
			        .catch(() => {});
			    },
			// 进入专利发布入口
			    zl() {
			      this.$router.push({
			        path: "/ZRelease",
			      });
			    },
			    // 进入专题页面
			    need(id) {
			       this.$router.push({
			        path: "/Patent",
			         query: {
			          i: id,
			        },
			      });
			    },
			    // 进入详情
			    xq(id) {
			      this.$router.push({
			        path: "/ZReleaseXQ",
			        query: {
			          id: id,
			        },
			      });
			    },
				
			    // 成果详情
			    cxq(id) {
			      this.$router.push({
			        path: "/Achievement_details",
			        query: {
			          id: id,
			        },
			      });
			    },
			    // 咨询客服
			    zx() {
			      this.$router.push({
			        path: "/chat",
			      });
			    },
				// 技术展示
				getList() {
				      achievementsLists({ page: 1, limits: 8 })
				        .then((res) => {
							console.log('aaaaaaaaa',res)
				          this.WisdomListe = res.data.data;
				        })
				        .catch(() => {});
				    },
					goTomember(){
						this.$router.push({
						  path: "/member3",
						});
					}
		}
	};
</script>

<style scoped lang="less">
	.banxin {
		width: 1400px;
		margin: 0 auto;
	}
	
	.pt60 {
		padding-top: 60px;
	}
	
	.qiyetop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 60px;
	
		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}
	
		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}
	
		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}
	
	.gongtop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 25px;
	
		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}
	
		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}
	
		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}
	
	.jisubox {
		padding-bottom: 60px;
		display: flex;
		background-color: #f6f6f6;
		.chanbg{
			margin-top: 60px;
			width: 100%;
			height: 260px;
			border-radius: 5px;
			background: url('../../../assets/img/images/chanbg_03.png') no-repeat center;
			background-size: 100% 260px;
			.channei{
				padding: 33px 0 0 40px;
				width: 69%;
				.chantop{
					font-size: 42px;
					color: #ffffff;
					font-weight: bold;
					padding-bottom: 18px;
				}
				.chantxt{
					font-size: 18px;
					color: #ffffff;
					line-height: 30px;
					padding-bottom: 25px;
				}
				.chanjin{
					width: 210px;
					height: 42px;
					border-radius: 30px;
					background-color: #ffffff;
					text-align: center;
					line-height: 42px;
					box-shadow: 0 2px 4px #222222;
					color: #2e6fdc;
					font-size: 18px;
					cursor: pointer;
					transition: all 0.5s;
				}
				.chanjin:hover{
					transform: translateX(10%);
				}
			}
		}
	}
	.quanbg{
		width: 100%;
		height: 380px;
		background: url('../../../assets/img/images/quanbg_06.png') no-repeat center;
		background-size: 100% 380px;
		.peotitle{
			font-size: 16px;
			color: #ffffff;
			text-align: center;
			cursor: pointer;
		}
	}
	.dingbg{
		width: 100%;
		height: 380px;
		background: url('../../../assets/img/images/rbg.png') no-repeat center;
		background-size: 100% 380px;
		.peotitle{
			font-size: 16px;
			color: #ffffff;
			text-align: center;
		}
	}
	.jishubg{
		width: 100%;
		height: 380px;
		background: url('../../../assets/img/images/jishubg_12.png') no-repeat center;
		background-size: 100% 380px;
		.peotitle{
			font-size: 16px;
			color: #ffffff;
			text-align: center;
		}
	}
	.jingbox{
		display: flex;
		background-color: #f6f6f6;
		padding-bottom: 60px;
		.jingbai{
			padding: 30px 20px 40px;
			background-color: #ffffff;
			border-radius: 7px;
			margin-top: -210px;
			.peoul{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				padding-bottom: 10px;
				.peoli:nth-child(4n){
					margin-right: 0;
				}
				.peoli{
					width: 325px;
					height: 200px;
					border-radius: 5px;
					box-shadow: 0 0 6px #dddddd;
					margin: 0 20px 20px 0;
					position: relative;
					background-image: linear-gradient(#f8fcfd,#ffffff);
					padding: 0 15px 0 20px;
					cursor: pointer;
					transition: all 0.5s;
					.peotitle{
						padding: 23px 0 12px;
						font-size: 20px;
						color: #222222;
						font-weight: bold;
					}
					.fuul{
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						padding-bottom: 11px;
						height: 90px;
						width: 90%;
						
						.fuli{
							font-size: 15px;
							color: #adb0b9;
							line-height: 25px;
							height: 25px;
							border: 1px solid #adb0b9;
							padding: 0 5px;
							margin: 0 10px 10px 0;
						}
					}
					.kfbox{
						display: flex;
						align-items: center;
						.kfleft{
							width: 123px;
							height: 37px;
							background-color: #e9f5fd;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							margin-right: 10px;
							.kfpic{
								width: 15px;
								height: 15px;
							}
							.kfltxt{
								font-size: 16px;
								color: #0289f4;
								padding-left: 5px;
							}
						}
						.flmian{
							width: 65px;
							background-color: #fef3eb;
							height: 37px;
							line-height: 37px;
							font-size: 16px;
							text-align: center;
							color: #ff7200;
							margin-right: 70px;
							cursor: pointer;
						}
						.yanyar{
							width: 20px;
							height: 20px;
							cursor: pointer;
						}
					}
				}
				.peoli:hover{
					background-image: linear-gradient(#0289f4,#0289f4);
					.peotitle{
						color: #ffffff;
					}
					.fuul{
						.fuli{
							color: #ffffff;
							border: 1px solid #ffffff;
						}
					}
				}
			}
			.zhuce{
				width: 205px;
				height: 40px;
				margin: 0 auto;
				cursor: pointer;
				background-color: #ff4200;
				font-size: 18px;
				color: #ffffff;
				text-align: center;
				border-radius: 5px;
				line-height: 40px;
			}
		}
	}
	.ruanbox{
		background-color: #f6f6f6;
		padding-bottom: 60px;
		.rubox{
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			.ruleft{
				cursor: pointer;
				flex-shrink: 0;
				width: 330px;
				height: 415px;
				background: url('../../../assets/img/images/ruanbg_03.png') no-repeat center;
				background-size: 100% 415px;
				padding: 0 28px;
				border-radius: 5px;
				.rutitle{
					font-size: 28px;
					color: #ffffff;
					font-weight: bold;
					padding: 37px 0 22px;
				}
				.rutxt{
					font-size: 18px;
					color: #ffffff;
					line-height: 28px;
					padding-bottom: 33px;
				}
				.rukbox{
					width: 140px;
					height: 35px;
					border: 1px solid #ffffff;
					text-align: center;
					line-height: 35px;
					font-size: 18px;
					color: #ffffff;
					cursor: pointer;
					transition: all 0.5s;
					border-radius: 8px;
				}
				.rukbox:hover{
					transform: translateY(-10%);
					background-color: #ffffff;
					color: #0289f4;
				}
			}
			.ruact{
				background: url('../../../assets/img/images/rubg_09.png') no-repeat center ;
				border-radius: 8px;
			}
			.ruyou{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				.peoli{
					width: 340px;
					height: 200px;
					border-radius: 5px;
					box-shadow: 0 0 6px #dddddd;
					margin: 0 0 15px 16px;
					position: relative;
					background-image: linear-gradient(#f8fcfd,#ffffff);
					padding: 0 15px 0 20px;
					cursor: pointer;
					.peotitle{
						padding: 23px 0 12px;
						font-size: 20px;
						color: #222222;
						font-weight: bold;
					}
					.fuul{
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						padding-bottom: 11px;
						height: 90px;
						width: 90%;
						.fuli{
							font-size: 15px;
							color: #adb0b9;
							line-height: 25px;
							height: 25px;
							border: 1px solid #adb0b9;
							padding: 0 5px;
							margin: 0 10px 10px 0;
						}
					}
					.kfbox{
						display: flex;
						align-items: center;
						.kfleft{
							width: 123px;
							height: 37px;
							background-color: #e9f5fd;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							margin-right: 10px;
							.kfpic{
								width: 15px;
								height: 15px;
							}
							.kfltxt{
								font-size: 16px;
								color: #0289f4;
								padding-left: 5px;
							}
						}
						.flmian{
							width: 65px;
							background-color: #fef3eb;
							height: 37px;
							line-height: 37px;
							font-size: 16px;
							text-align: center;
							color: #ff7200;
							margin-right: 70px;
							cursor: pointer;
						}
						.yanyar{
							width: 20px;
							height: 20px;
							cursor: pointer;
						}
					}
				}
				.peoli:hover{
					background-image: linear-gradient(#0289f4,#0289f4);
					.peotitle{
						color: #ffffff;
					}
					.fuul{
						.fuli{
							color: #ffffff;
							border: 1px solid #ffffff;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.banxin{
			width: 98%;
			margin: 0 auto;
			display: block;
		}
		.jisubox .chanbg .channei .chantop{
			font-size: 2.6rem;
		}
		.jisubox .chanbg .channei .chantxt{
			padding-bottom: 0;
			margin-bottom: 1rem;
			font-size: 1.2rem;
		}
		.jisubox .chanbg .channei .chanjin{
			width: 15rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1.3rem;
		}
		.gongtop .zhengbai{
			font-size: 3rem;
		}
		.quanbg{
			height: 23rem;
			background-size: cover;
		}
		.quanbg .peotitle{
			font-size: 1.6rem;
			line-height: 2.1rem;
		}
		.jingbox .jingbai .peoul{
			justify-content: space-between;
		}
		.jingbox .jingbai .peoul .peoli{
			width: 49%;
			height: 18rem;
			margin: 0 0 1.5rem;
		}
		.jingbox .jingbai .peoul .peoli .peotitle{
			font-size: 1.8rem;
		}
		.jingbox .jingbai .peoul .peoli .fuul{
			height: 10rem;
		}
		.jingbox .jingbai .peoul .peoli .fuul .fuli{
			font-size: 1.4rem;
			height: 2.5rem;
			line-height: 2.5rem;
		}
		.jingbox .jingbai .peoul .peoli .kfbox .kfleft{
			width: 10rem;
			height: 2.8rem;
		}
		.jingbox .jingbai .peoul .peoli .kfbox .kfleft .kfpic{
			width: 1.5rem;
			height: 1.5rem;
		}
		.jingbox .jingbai .peoul .peoli .kfbox .kfleft .kfltxt{
			font-size: 1.5rem;
		}
		.jingbox .jingbai .peoul .peoli .kfbox .flmian{
			height: 2.8rem;
			line-height: 2.8rem;
			width: 6rem;
			font-size: 1.5rem;
			margin-right: 2.5rem;
		}
		.jingbox .jingbai .peoul .peoli .kfbox .yanyar{
			width: 1.8rem;
			height: 1.8rem;
		}
		.jingbox .jingbai .zhuce{
			width: 15rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1.6rem;
		}
		.qiyetop .zhengtitle{
			font-size: 3rem;
		}
		.ruanbox .rubox{
			flex-wrap: wrap;
		}
		.ruanbox .rubox .ruleft{
			width: 100%;
			height: 25rem;
			background-size: 100% 25rem;
		}
		.ruanbox .rubox .ruleft .rutitle{
			font-size: 2.2rem;
			padding-bottom: 1.5rem;
		}
		.ruanbox .rubox .ruleft .rutxt{
			font-size: 1.6rem;
			line-height: 2.3rem;
		}
		.ruanbox .rubox .ruleft .rukbox{
			line-height: 3.5rem;
			width: 18rem;
			font-size: 1.8rem;
			height: 3.5rem;
		}
		
		
		
		.ruanbox .rubox .ruyou{
			overflow: hidden;
			justify-content: space-between;
			padding-top: 2rem;
		}
		.ruanbox .rubox .ruyou  .peoli{
			width: 49%;
			height: 18rem;
			margin: 0 0 1.5rem;
		}
		.ruanbox .rubox .ruyou .peoli .peotitle{
			font-size: 1.8rem;
		}
		.ruanbox .rubox .ruyou .peoli  .fuul{
			height: 10rem;
		}
		.ruanbox .rubox .ruyou .peoli .fuul .fuli{
			font-size: 1.4rem;
			height: 2.5rem;
			line-height: 2.5rem;
		}
		.ruanbox .rubox .ruyou .peoli .kfbox .kfleft{
			width: 10rem;
			height: 2.8rem;
		}
		.ruanbox .rubox .ruyou .peoli .kfbox .kfleft .kfpic{
			width: 1.5rem;
			height: 1.5rem;
		}
		.ruanbox .rubox .ruyou .peoli .kfbox .kfleft .kfltxt{
			font-size: 1.5rem;
		}
		.ruanbox .rubox .ruyou .peoli .kfbox .flmian{
			height: 2.8rem;
			line-height: 2.8rem;
			width: 6rem;
			font-size: 1.5rem;
			margin-right: 2.5rem;
		}
		.ruanbox .rubox .ruyou .peoli .kfbox .yanyar{
			width: 1.8rem;
			height: 1.8rem;
		}
		.dingbg{
			height: 23rem;
			background-size: cover;
		}
		.dingbg .peotitle{
			font-size: 1.6rem;
		}
	}
</style>